import { generalMessageTemplate } from "../entities/generalMessageTemplate";

let socketInstance = null;
const listeners = []; // To notify React components about messages and status changes
let shouldReconnect = true; // Flag to control reconnection

export const setupWebSocket = (wsUrl, currentUser) => {
  if (socketInstance && (socketInstance.readyState === WebSocket.CONNECTING || socketInstance.readyState === WebSocket.OPEN)) {
    console.log("WebSocket is already initialized.");
    return socketInstance;
  }

  shouldReconnect = true; // Ensure reconnection is allowed if needed

  socketInstance = new WebSocket(wsUrl);

  socketInstance.onopen = () => {
    //console.log("WebSocket connection established.");
    if (currentUser) {
      const generalMessage = { ...generalMessageTemplate };
      generalMessage.messageType = "user_login_social";
      generalMessage.browserId = localStorage.getItem("browserId");
      generalMessage.user.socialUser = currentUser;
      socketInstance.send(JSON.stringify(generalMessage));
    }
    notifyListeners({ type: "status", status: "open" });
  };

  socketInstance.onmessage = (event) => {
    const message = JSON.parse(event.data);

    notifyListeners({ type: "message", message });

    // Handle logout: stop reconnecting and reset WebSocket
    if (message.messageType === "user_logged_out") {
      //console.log("Logout received. Closing WebSocket...");
      //shouldReconnect = false;
      //resetWebSocket(wsUrl, currentUser);
      //window.location.href = "/login";
      //window.location.reload(); // Ensures a fresh state after redirect
    }
  };

  socketInstance.onclose = () => {
    console.log("WebSocket closed.");
    notifyListeners({ type: "status", status: "closed" });

    if (shouldReconnect) {
      console.log("Attempting WebSocket reconnection...");
      setTimeout(() => {
        setupWebSocket(wsUrl, currentUser);
      }, 5000); // Retry after 5 seconds (increase delay to avoid loops)
    }
  };

  socketInstance.onerror = (error) => {
    console.error("WebSocket error:", error);
    notifyListeners({ type: "status", status: "error" });
  };

  return socketInstance;
};

// Reset WebSocket instance on logout
export const resetWebSocket = (wsUrl, currentUser) => {
  if (socketInstance) {
    shouldReconnect = false; // Prevent automatic reconnection
    socketInstance.onclose = null; // Remove old event listeners
    socketInstance.close();
    socketInstance = null;
  }
  // Reconnect immediately with the new session ID
  shouldReconnect = true; // Allow reconnection again
  setupWebSocket(wsUrl, currentUser);
};
export const getWebSocketInstance = () => socketInstance;

export const addListener = (listener) => {
  if (typeof listener === "function") {
    listeners.push(listener);
  }
};

export const removeListener = (listener) => {
  const index = listeners.indexOf(listener);
  if (index > -1) {
    listeners.splice(index, 1);
  }
};

const notifyListeners = (event) => {
  listeners.forEach((listener) => {
    try {
      listener(event);
    } catch (error) {
      console.error("Error in listener:", error);
    }
  });
};

export const getSocket = () => socketInstance;

export const sendMessage = (message) => {
  if (socketInstance && socketInstance.readyState === WebSocket.OPEN) {
    let browserId = localStorage.getItem("browserId");
    message.browserId = browserId;
    socketInstance.send(JSON.stringify(message));
  } else {
    console.warn("WebSocket is not connected. Cannot send message.");
  }
};


