import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider, useAuth } from "./AuthContext";
import i18n from "./services/i18n";
import { I18nextProvider } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import { setupWebSocket } from "./services/websocketManager"; // Import the WebSocket manager
import { GENERAL } from "./general/General";
import { UserProvider } from "./authMethods/UserContext";

import "../src/authMethods/firebaseConfig"; // Import the Firebase configuration file


const WebSocketInitializer = ({ children }) => {
  const { currentUser } = useAuth(); // Access user info from AuthContext
  const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

  React.useEffect(() => {
    const wsUrl = isLocalhost
      ? GENERAL.SERVER_URL_HTTP
      : GENERAL.SERVER_URL_HTTPS;

    console.log("WebSocket URL:", wsUrl);
    setupWebSocket(wsUrl, currentUser);
  }, [currentUser, isLocalhost]);
  return children;
};

const renderApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <WebSocketInitializer>
          <Router>
            <UserProvider>
              <App />
            </UserProvider>
          </Router>
        </WebSocketInitializer>
      </AuthProvider>
    </I18nextProvider>
  );
};

// ✅ Detect if running inside Facebook (iframe and FBInstant available)
const isFacebookIframe = () => {
  return (
    typeof window.FBInstant !== "undefined" &&
    typeof window.FBInstant.initializeAsync === "function" &&
    window.top !== window.self // inside iframe
  );
};

if (isFacebookIframe()) {
  console.log("Running inside Facebook, initializing FBInstant...");
  window.FBInstant.initializeAsync()
    .then(() => {
      console.log("FBInstant initialized");
      return window.FBInstant.startGameAsync();
    })
    .then(() => {
      console.log("Game started via FBInstant");
      const FBInstant = window.FBInstant;

      // ✅ Get and store FB player info
      const fbPlayer = {
        id: FBInstant.player?.getID() || null,
        name: FBInstant.player?.getName(),
        photo: FBInstant.player?.getPhoto(),
      };

      // Temporarily save on window to access in App if needed
      window.FB_USER_DATA = fbPlayer;

      console.log("👤 FB Player:", fbPlayer);
      localStorage.setItem("fbPlayer", JSON.stringify(fbPlayer));

      renderApp();
    })
    .catch((err) => {
      console.error("FBInstant error:", err);
      renderApp();
    });
} else {
  console.log("Not in Facebook, rendering app normally");
  renderApp();
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
