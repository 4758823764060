import { useEffect, useRef } from "react";
import { useAuth } from "../AuthContext";
import { sendMessage, getSocket } from "../services/websocketManager";
import { generalMessageTemplate } from "../entities/generalMessageTemplate";

const UserDataSync = () => {
  const { currentUser } = useAuth();
  const sentLoginRef = useRef(false); // ✅ Prevents resending

  useEffect(() => {
    // ✅ Firebase Auth User
    if (currentUser && !sentLoginRef.current) {
      const generalMessage = {
        ...generalMessageTemplate,
        user: {
          ...generalMessageTemplate.user,
          email: currentUser.email,
          userName: currentUser.email,
          socialUser: currentUser,
        },
        messageType: "user_login",
      };

      sendMessage(generalMessage);
      sentLoginRef.current = true;
    }

    // ✅ Facebook Instant Game User
    else if (window.FBInstant && window.FB_USER_DATA && !sentLoginRef.current) {
      const fbUser = window.FB_USER_DATA;

      const generalMessage = {
        ...generalMessageTemplate,
        user: {
          ...generalMessageTemplate.user,
          email: fbUser.id + "@fb.com",
          userName: fbUser.id + "@fb.com",
          url: fbUser.photo,
          socialUser: {
            displayName: fbUser.name,
            email: fbUser.id + "@fb.com",
            photoURL: fbUser.photo,
            providerId: "facebook.com",
            uid: fbUser.id,
          },
        },
        messageType: "user_login",
      };

      const waitForSocketAndSend = () => {
        const socket = getSocket();
        if (socket && socket.readyState === WebSocket.OPEN) {
          sendMessage(generalMessage);
          sentLoginRef.current = true;
        } else {
          setTimeout(waitForSocketAndSend, 100);
        }
      };

      waitForSocketAndSend();
    }

    // ✅ Handle logout (optional)
    else if (!sentLoginRef.current) {
      const logoutMessage = {
        ...generalMessageTemplate,
        messageType: "user_logged_out",
      };
      // Optional: sendMessage(logoutMessage);
    }
  }, [currentUser]);

  return null; // Invisible component
};

export default UserDataSync;
