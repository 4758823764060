import React, { useState } from "react";
import LanguageIcon from '@mui/icons-material/Language';

const CountryFlag = ({ countryCode }) => {
  const isMobile = window.innerWidth <= 600; // Check if the screen size is mobile
  const [imgError, setImgError] = useState(false);
  if (!countryCode) return <div></div>;

  //const flagUrl = `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`;
  const flagUrl = `${process.env.PUBLIC_URL}/${countryCode.toLowerCase()}.svg`;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
      {!imgError ? (
        <img
          src={flagUrl}
          alt={`Flag of ${countryCode}`}
          style={{
            width: isMobile ? "30px" : "60px",
            height: isMobile ? "20px" : "40px",
            borderRadius: "1px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          onError={() => setImgError(true)}
        />
      ) : (
        <LanguageIcon sx={{ fontSize: isMobile ? 20 : 40 }} />
      )}
      <span>{countryCode.toUpperCase()}</span>
    </div>
  );
};

export default CountryFlag;
